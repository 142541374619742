import {
  FailedMappingUserIdsError,
  FailedSignupProvisioningError,
  GCPAccountNotFoundError,
  GCPInvalidSSOError,
  GCPUserNotFoundError,
  HerokuAccountNotFoundError,
  HerokuInvalidSSOError,
  VercelInstallationNotFoundError,
  LazyPasswordMigrationFailedError,
  LinkingFailedError,
  MustConfirmEmailError,
  ReCaptchaInvalid,
  UserLoginLockedError,
} from '@/lib/auth/exceptions';

export const FRONTEND_CONTROL_ERROR_MESSAGES = {
  [FailedSignupProvisioningError.className]:
    'Unable to finish signing up, please try again',
  [FailedMappingUserIdsError.className]:
    'Unable to finish signing up, please try again',
  [MustConfirmEmailError.className]:
    'Please confirm your email before logging in with a different login method than email/password.',
  [LinkingFailedError.className]:
    'Unable to log in with this signup method, please log in with your original signup method.',
  [LazyPasswordMigrationFailedError.className]:
    'There was an error completing your login, please try again',
  [UserLoginLockedError.className]:
    'Your account is locked, please contact support to unlock it',
  [HerokuInvalidSSOError.className]:
    'Signing in with Heroku failed, please try again.',
  [HerokuAccountNotFoundError.className]:
    'Your Heroku account was not found, please contact support.',
  [VercelInstallationNotFoundError.className]:
    'Your Vercel installation was not found, please contact support.',
  [GCPAccountNotFoundError.className]:
    'Your GCP account was not found, please contact support.',
  [GCPUserNotFoundError.className]:
    'Your GCP user was not found, please contact support.',
  [GCPInvalidSSOError.className]:
    'Signing in with GCP failed, please try again.',
  [ReCaptchaInvalid.className]: 'Error verifying ReCaptcha, please try again.',
};

export const frontendControlErrorMessage = (
  exceptionName: string,
  defaultMsg: string = 'Something went wrong, please try again'
) => {
  return exceptionName in FRONTEND_CONTROL_ERROR_MESSAGES
    ? FRONTEND_CONTROL_ERROR_MESSAGES[exceptionName]
    : defaultMsg;
};
