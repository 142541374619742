interface NamedError {
  getName: () => string;
}

export function shouldReportErrorToRollbar(error: unknown): boolean {
  const errorExplicitlyMarkedAsIgnored =
    typeof error === 'object' &&
    error !== null &&
    'shouldNotReportToRollbar' in error &&
    (error as { shouldNotReportToRollbar: boolean })
      .shouldNotReportToRollbar === true;

  return !errorExplicitlyMarkedAsIgnored;
}

export class SupertokensError extends Error {
  message: string;
  rollbarData: any;

  constructor(message: string, rollbarData: any) {
    super(message);
    this.message = message;
    this.rollbarData = rollbarData;
  }
}

/**
 * Base class of errors that we use to actually control what
 * displays in the frontend
 */
export class FrontendControlError
  extends SupertokensError
  implements NamedError
{
  extraResponseData?: object;
  static className = 'FrontendControlError';

  constructor(message: string, rollbarData: any, extraResponseData?: object) {
    super(message, rollbarData);
    this.extraResponseData = extraResponseData;
  }

  getName() {
    return FrontendControlError.className;
  }
}

/**
 * Thrown when we could not successfully make the connection between
 * our system and supertokens. When this happens, they will need
 * to attempt to log in so we can finish provisioning.
 */
export class FailedSignupProvisioningError
  extends FrontendControlError
  implements NamedError
{
  static className = 'FailedSignupProvisioningError';

  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
    this.message = message;
    this.rollbarData = rollbarData;
  }

  getName() {
    return FailedSignupProvisioningError.className;
  }
}

/**
 * Thrown when we were unable to make the rollbar user <> supertokens
 * user connection, even though both users were created.
 */
export class FailedMappingUserIdsError
  extends FrontendControlError
  implements NamedError
{
  static className = 'FailedMappingUserIdsError';

  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
    this.message = message;
    this.rollbarData = rollbarData;
  }

  getName() {
    return FailedMappingUserIdsError.className;
  }
}

/**
 * Thrown when a user attempts to sign in with SAML, but they do not
 * have a user in rollbar and the account they are trying to sign into
 * does not have domain suggestions turned on.
 *
 * This should be handled by the frontend in a friendly way, displaying
 * an error page that the user needs to contact their administrator.
 */
export class SAMLManualProvisioningRequired
  extends FrontendControlError
  implements NamedError
{
  static className = 'SAMLManualProvisioningRequired';

  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }

  getName() {
    return SAMLManualProvisioningRequired.className;
  }
}

/**
 * Thrown when a user signs in via SAML and the email belongs to a user
 * in a different account
 */
export class SAMLEmailBelongsToDifferentAccount
  extends FrontendControlError
  implements NamedError
{
  static className = 'SAMLEmailBelongsToDifferentAccount';

  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }

  getName() {
    return SAMLEmailBelongsToDifferentAccount.className;
  }
}

/**
 * Thrown when a tenant in supertokens is not configured correctly,
 * e.g. the tenant id doesn't match our format of rollbar-acct-{accountId}.
 */
export class TenantIncorrectlyConfiguredError
  extends SupertokensError
  implements NamedError
{
  static className = 'TenantIncorrectlyConfiguredError';

  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }

  getName() {
    return TenantIncorrectlyConfiguredError.className;
  }
}

/**
 * Thrown when the user already has already logged in with only email / password,
 * has not verified their email, then attempts to log in with social. For these accounts
 * to be associated, they must verify their email
 */
export class MustConfirmEmailError
  extends FrontendControlError
  implements NamedError
{
  static className = 'MustConfirmEmailError';

  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }

  getName() {
    return MustConfirmEmailError.className;
  }
}

export class LinkingFailedError extends FrontendControlError {
  static className = 'LinkingFailedError';

  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }

  getName() {
    return LinkingFailedError.className;
  }
}

/**
 * Thrown when migrating a user on email/password login fails, so we
 * cannot make the connection
 */
export class LazyPasswordMigrationFailedError extends FrontendControlError {
  static className = 'LazyPasswordMigrationFailedError';

  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }

  getName() {
    return LazyPasswordMigrationFailedError.className;
  }
}

/**
 * Thrown when a user is locked out of their account, and unable to log in
 */
export class UserLoginLockedError extends FrontendControlError {
  static className = 'UserLoginLockedError';

  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }
  getName() {
    return UserLoginLockedError.className;
  }
}

/**
 * Thrown when a heroku sso attempt failed hash validation
 */
export class HerokuInvalidSSOError extends FrontendControlError {
  static className = 'HerokuInvalidSSOError';

  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }

  getName() {
    return HerokuInvalidSSOError.className;
  }
}

/**
 * Thrown when the account was not found, either in heroku or in our system
 */
export class HerokuAccountNotFoundError extends FrontendControlError {
  static className = 'HerokuAccountNotFoundError';

  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }

  getName(): string {
    return HerokuAccountNotFoundError.className;
  }
}

export class GCPInvalidSSOError extends FrontendControlError {
  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }
}

export class GCPAccountNotFoundError extends FrontendControlError {
  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }
}

export class GCPUserNotFoundError extends FrontendControlError {
  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }
}

export class VercelInstallationNotFoundError extends FrontendControlError {
  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }
}

export class GithubAppSSOFailedError extends FrontendControlError {
  static className = 'GithubAppSSOFailedError';

  constructor(message: string, rollbarData: any, extraResponseData?: object) {
    super(message, rollbarData, extraResponseData);
  }

  getName() {
    return GithubAppSSOFailedError.className;
  }
}

export class TotpRecoveryCodeInvalid extends FrontendControlError {
  static className = 'TotpRecoveryCodeInvalid';

  constructor(message: string, rollbarData: any) {
    super(message, rollbarData);
  }

  getName() {
    return TotpRecoveryCodeInvalid.className;
  }
}

export class ReCaptchaInvalid extends FrontendControlError {
  static className = 'ReCaptchaInvalid';
  // we don't want to report this to rollbar, because that means we could
  // just spam ourselves if there is an attempted hacking
  shouldNotReportToRollbar: boolean;

  constructor(message: string, rollbarData: any, extraResponseData?: object) {
    super(message, rollbarData, extraResponseData);

    this.shouldNotReportToRollbar = true;
  }

  getName(): string {
    return ReCaptchaInvalid.className;
  }
}
